<!--
 * @Description: 房源设施
 * @Author: 琢磨先生
 * @Date: 2022-05-09 10:59:38
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-02 10:27:20
-->

<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit(0)" round icon="plus"
      >新增</el-button
    >
    <el-button type="success" size="small" @click="onEdit(1)" round icon="plus"
      >新增分组</el-button
    >
  </el-card>
  <el-card class="box data">
    <el-scrollbar>
      <el-collapse v-model="activeNames" :accordion="false" v-loading="loading">
        <el-collapse-item
          v-for="(item, index) in tableData"
          :key="item.id"
          :title="item.name"
          :name="index"
        >
          <template #title>
            <b>{{ item.sequence }}、</b> {{ item.name }}({{
              item.children.length
            }})
            <el-button
              type="primary"
              link
              size="small"
              @click.stop="onEdit(1, item)"
              style="margin-left: 20px"
              >修改</el-button
            >

            <el-button
              type="text"
              class="del"
              v-on:click.stop="onDelete(item)"
              v-if="item.children.length == 0"
            >
              <el-icon><close-bold /></el-icon
            ></el-button>
          </template>
          <el-tag
            :type="model.is_stop ? 'danger' : ''"
            v-for="model in item.children"
            class="ml-2"
            :key="model.id"
            closable
            @close="onDelete(model)"
            ><b>{{ model.sequence }}、</b> {{ model.name
            }}<span
              v-if="model.is_search"
              style="color: var(--text-label-color)"
              >(可搜索)</span
            ><span v-if="model.is_stop" class="text-danger">(已停用)</span>
            <el-button
              type="primary"
              link
              size="small"
              icon="EditPen"
              style="margin-left: 5px"
              @click="onEdit(0, model)"
            ></el-button>
          </el-tag>
          <el-empty description="暂无数据" v-if="item.children.length == 0" />
        </el-collapse-item>
      </el-collapse>
    </el-scrollbar>
  </el-card>
  <edit-facility
    :action="action"
    :item="current"
    :reload="loadData"
    :groups="tableData"
  ></edit-facility>
</template>
<script>
import EditFacility from "./edit_facility.vue";
export default {
  components: {
    EditFacility,
  },
  data() {
    return {
      loading: false,
      activeNames: "0",
      action: 0,
      current: null,
      tableData: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.current = null;
      this.$http.get(`admin/v1/facility`).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 新增、修改
     */
    onEdit(type, item) {
      this.action = type;
      this.current = item ? item : {};
    },
    onDelete(item) {
      this.$messageBox
        .confirm(`您却要删除-${item.name}？`, "提醒")
        .then(() => {
          this.$http(`admin/v1/facility/del?id=${item.id}`).then((res) => {
            if (res.code == 0) {
              this.loadData();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.del {
  margin-left: 20px;
  color: var(--el-color-danger);
}
.ml-2 {
  margin-right: 20px;
  margin-bottom: 10px;
}
</style>
